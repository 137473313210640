import { collection, query } from "firebase/firestore";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import { AnyDoc } from "../interfaces/shared";
import { getEnvironment } from "../utils/enviroment.utils";

const useContent = (): {
  getAll: () => AnyDoc[];
} => {
  /**Firestore reference */
  const firestore = useFirestore();
  //content collection
  const contentCollection = collection(firestore, "environments", "development", "Content");

  //get data
  const { data: collectionData } = useFirestoreCollectionData(contentCollection, {
    initialData: null,
    idField: "contentName",
  });

  const getAll = (): AnyDoc[] => collectionData as AnyDoc[];

  return {
    getAll,
  };
};

export default useContent;
