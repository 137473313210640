const useImagePreloader = () => {
  const preload = (url: string): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      const preloader = new Image();
      preloader.src = url;

      preloader.addEventListener("load", (event) => {
        resolve(true);
      });

      preloader.addEventListener("error", (event) => {
        reject(false);
      });
    });
  };

  /**
   * Do a bulk preload of images
   * @param {string[]} urls Images to preload
   * @returns {Promise<boolean[]>} Results of preloading
   */
  const bulkPreload = (urls: string[]): Promise<boolean[]> => {
    return Promise.all(urls.map((url) => preload(url)));
  };

  return {
    preload,
    bulkPreload,
  };
};

export default useImagePreloader;
