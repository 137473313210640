//React imports
import React, { useEffect, useState } from "react";
import useContent from "../hooks/useContent";
import { ContactDoc } from "../interfaces/contact";
import { AnyDoc } from "../interfaces/shared";
import { HeaderDoc } from "../interfaces/header";
import useImagePreloader from "../hooks/useImagePreloader";
import { TeamDoc } from "../interfaces/team";
import { ServicesDoc } from "../interfaces/services";
import { HomeDoc } from "../interfaces/home";

//Definitions
/**Context functions */
interface PreloadContextProviderValues {
  /**Get a specific view data */
  getViewData: (
    id: "Header" | "Footer" | "Home" | "About" | "Contact" | "Services" | "Team"
  ) => AnyDoc | undefined;

  isReadyToLoad: boolean;
}

/**Preload context */
export const PreloadContext = React.createContext<PreloadContextProviderValues>({
  getViewData: () => undefined,
  isReadyToLoad: false,
});

const PreloadProvider = ({ children }: { children: any }) => {
  /**All documents snap shot */
  const allDocsSnap: AnyDoc[] = useContent().getAll();

  //Preload hook
  const { bulkPreload } = useImagePreloader();

  //Data
  //All images loaded flag
  const [allImagesLoaded, setAllImagesLoaded] = useState(false);

  //Methods
  /**Get view data */
  const getViewData = (
    id: "Header" | "Footer" | "Home" | "About" | "Contact" | "Services" | "Team"
  ): AnyDoc | undefined => allDocsSnap?.find((doc) => (doc as any).contentName === id);

  /**Preload images */
  const preloadImages = async () => {
    if (allDocsSnap) {
      /**Home data */
      const homeData = getViewData("Home")! as unknown as HomeDoc;

      /**Header data */
      const headerData = getViewData("Header")! as HeaderDoc;

      /**Contact data */
      const contactData = getViewData("Contact")! as ContactDoc;

      // /**Team data */
      const teamData = getViewData("Team")! as TeamDoc;

      /**service data */
      const servicesData = getViewData("Services")! as ServicesDoc;

      /**Images url array */
      let urls: string[] = [
        //nav logo
        headerData.logoUrl,
        teamData.banner.backgroundImage.url,
        ...homeData.slider.images.map(({ url }) => url),
        contactData.banner.backgroundImage.url,
        teamData.banner.backgroundImage.url,
        servicesData.banner.backgroundImage.url,
        ...servicesData.serviceList.map((item) => item.backgroundImage.url),
      ];

      while (urls.length > 0) {
        //While there are images needed of loading
        /**Preload reults */
        const results = await bulkPreload(urls);

        /**Successfull indexes */
        const successfullIndexes = results.map((res, i) => (res ? i : null)).filter((idx) => idx !== null);

        //Remove urls, allow to stay only those that are not in the successful indexes
        urls = urls.filter((_, i) => successfullIndexes.indexOf(i) === -1);
      }

      //Set all images as loaded
      setAllImagesLoaded(true);
    }
  };

  //console.log(allDocsSnap && allImagesLoaded, allDocsSnap, allImagesLoaded);

  //Use effect
  useEffect(() => {
    if (!allImagesLoaded && allDocsSnap) {
      //Images not laoded yet and data from firestore fetched
      //Do preload images
      preloadImages();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allDocsSnap]);

  return (
    <PreloadContext.Provider
      value={{
        getViewData,
        isReadyToLoad: allDocsSnap && allImagesLoaded,
      }}
    >
      {children}
    </PreloadContext.Provider>
  );
};

export { PreloadProvider };
