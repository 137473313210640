//Third parties
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { FirestoreProvider, StorageProvider, useFirebaseApp, FunctionsProvider } from "reactfire";
import { getFirestore } from "firebase/firestore";
//Local imports
import { routes } from "./routes/index";
//Styles
import "./App.scss";
import { Header } from "./components/header/header.component";
import { useContext } from "react";
import { PreloadContext, PreloadProvider } from "./context/preload.context";
import AppRouter from "./routes/appRouter";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";

function App() {
  /**Firestore instance */
  const firestoreInstance = getFirestore(useFirebaseApp());

  const storageInstance = getStorage(useFirebaseApp());

  const functionsInstance = getFunctions(useFirebaseApp());

  return (
    <FirestoreProvider sdk={firestoreInstance}>
      <StorageProvider sdk={storageInstance}>
        <FunctionsProvider sdk={functionsInstance}>
          <PreloadProvider>
            <AppRouter />
          </PreloadProvider>
        </FunctionsProvider>
      </StorageProvider>
    </FirestoreProvider>
  );
}

export default App;
