import React from "react";
import { useContext } from "react";
//Third parties
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
//Local imports
import { routes } from "./index";

import { PreloadContext } from "../context/preload.context";
import { Header } from "../components/header/header.component";
import Footer from "../components/footer/footer.component";
import Spinner from "../components/spinner/spinner.component";

const AppRouter = () => {
  const { isReadyToLoad } = useContext(PreloadContext);

  return (
    <>
      {isReadyToLoad ? (
        <Router>
          <Header />
          <Routes>
            {routes.map((route, i) => (
              <Route {...route} key={`public-routes-route-${i}`} />
            ))}
            <Route path="*" element={() => <Navigate to="/" replace={true} />} />
          </Routes>
          <Footer />
        </Router>
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default AppRouter;
