import { useContext, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
//interface
import { HeaderDoc } from "../../interfaces/header";
import { PreloadContext } from "../../context/preload.context";
//custom hooks
import useScreenSize from "../../hooks/useScreenSize";
//styles
import "./header.component.scss";

/**Header component */
export const Header = () => {
  //Preload context
  const { getViewData, isReadyToLoad } = useContext(PreloadContext);
  //screen size hook
  const { sizeStatus } = useScreenSize();
  //close menu if size > 768px
  useEffect(() => {
    if (sizeStatus > 768) {
      setActiveMenu(false);
    }
  }, [sizeStatus]);

  const [data, setData] = useState<HeaderDoc | undefined>(getViewData("Header") as HeaderDoc | undefined);
  //open and close menu variable
  const [activeMenu, setActiveMenu] = useState(false);

  const showMenu = () => {
    return setActiveMenu(!activeMenu);
  };

  //On is ready to load
  useEffect(() => {
    if (isReadyToLoad) {
      setData(getViewData("Header") as HeaderDoc | undefined);
    }
    // eslint-disable-next-line
  }, [isReadyToLoad, getViewData("Header")]);

  if (!data) return <div></div>;

  return (
    <>
      <nav className={"nav"} style={{ position: activeMenu ? "fixed" : "absolute" }}>
        <div className="nav_container">
          <div className="nav_logo">
            <NavLink
              to={"/"}
              onClick={() => {
                setActiveMenu(false);
              }}
              style={({  }) => ({
                maxWidth: 100
              })}
            >
              <img src={data.logoUrl} alt="logo" className="nav_logo_img" />
            </NavLink>
          </div>

          <ul className="nav_links">
            {data?.items?.slice(1).map((item: any, index: number) => (
              <li className="nav_links_li" key={index}>
                <NavLink to={item.route} className="nav_links_item">
                  {item.name}
                </NavLink>
              </li>
            ))}
          </ul>

          <div className="nav_hamburger" onClick={() => showMenu()}>
            <img
              src={activeMenu ? data.exitMenuIcon : data.menuIcon}
              style={{ width: activeMenu ? "25px" : "35px" }}
              alt="menu"
              className="nav_hamburger_icon"
            />
          </div>
        </div>
      </nav>

      <div className={"menu__container"}>
        <nav className={activeMenu ? `${"menu"} ${"active"}` : "menu"}>
          <ul className={"menu__items"}>
            {data?.items?.slice(1).map((item: any, index: number) => (
              <li className="menu__item" key={index} onClick={() => showMenu()}>
                <NavLink to={item.route} className="menu__item__link">
                  {item.name}
                </NavLink>
              </li>
            ))}
          </ul>
          <div className="menu__icons">
            {data?.mediaIcons.map(({ url, route }, index) => (
              <a href={route} target="_blank" rel="noreferrer" key={index}>
                <img key={url} className="menu__icon" src={url} alt="" />
              </a>
            ))}
          </div>
        </nav>
      </div>
    </>
  );
};
