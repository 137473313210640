//Firebase imports
import "firebase/app";
import "firebase/firestore";

/**Firebase config */
export const firebaseClientConfig = {
  apiKey: "AIzaSyARGirm7v8N0Qf0DqM2BjnYqg7P07wd_Es",
  authDomain: "spsrisk-2022.firebaseapp.com",
  projectId: "spsrisk-2022",
  storageBucket: "spsrisk-2022.appspot.com",
  messagingSenderId: "356039005275",
  appId: "1:356039005275:web:241187eafedfad7abcdb7e",
  measurementId: "G-6VZL75M6HV",
  // apiKey: "API_KEY",
  // authDomain: "AUTH_DOMAIN",
  // databaseURL: "DATABASE_URL",
  // projectId: "PROJECT_ID",
  // storageBucket: "STORAGE_BUCKET",
  // messagingSenderId: "MESSAGING_SENDER_ID",
  // appId: "APP_ID",
  // measurementId: "MEASUREMENT_ID",
};
