import { useLayoutEffect, useState } from "react";

const useScreenSize = () => {
  const [sizeStatus, setSizeStatus] = useState(window.innerWidth);

  useLayoutEffect(() => {
    function updateSizeStatus() {
      setSizeStatus(window.innerWidth);
    }

    window.addEventListener("resize", updateSizeStatus);

    updateSizeStatus();

    return () => window.removeEventListener("resize", updateSizeStatus);
  }, [sizeStatus]);

  return { sizeStatus, setSizeStatus };
};

export default useScreenSize;
