//React imports
import React, { useEffect, useState } from "react";
//Local imports
import { GA_IDS } from "../config/gcloud.config";
import {
  GoogleAnalyticsContext,
  GTagEvent,
  GtagEvents,
} from "../interfaces/analytics";

export const AnalyticsContext = React.createContext<GoogleAnalyticsContext>({
  emitCustomEvent: () => {},
  emitPageView: () => {},
  emitScreenView: () => {},
  updateConsent: () => {},
  emitEvent: () => {},
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AnalyticsProvider = ({ children }: { children: any }): JSX.Element => {
  const [scriptLoaded, setScriptLoaded] = useState(false);
  /**
   * Wrapper for https://developers.google.com/analytics/devguides/collection/gtagjs/pages
   */
  const emitPageView = (page_title: string, page_path?: string) => {
    if (scriptLoaded && typeof window !== "undefined") {
      window.gtag("config", GA_IDS.TRACKING_GA4_ID, {
        page_path,
        page_title,
      });
    }
  };

  /**
   * Wrapper for custom events: https://developers.google.com/analytics/devguides/collection/gtagjs/events
   */
  const emitCustomEvent = ({
    action,
    category,
    label,
    value,
  }: GTagEvent): void => {
    if (scriptLoaded && typeof window !== "undefined") {
      window.gtag("event", action, {
        event_category: category,
        event_label: label,
        value: value,
      });
    }
  };

  /**
   * Wrapper for manual screen view: https://developers.google.com/analytics/devguides/collection/gtagjs/pages#manual_pageviews
   */
  const emitScreenView = (screen_name: string) => {
    if (scriptLoaded && typeof window !== "undefined") {
      window.gtag("event", "screen_view", {
        screen_name,
      });
    }
  };

  /**
   * Wrapper for events: https://support.google.com/analytics/answer/9267735
   */
  const emitEvent = (
    eventName: GtagEvents,
    params:
      | Gtag.ControlParams
      | Gtag.EventParams
      | Gtag.CustomParams
      | undefined
  ) => {
    if (scriptLoaded && typeof window !== "undefined") {
      window.gtag("event", eventName, {
        ...params,
      });
    }
  };

  /**
   * Wrapper for https://developers.google.com/gtagjs/devguide/consent#update_tag_behavior
   */
  const updateConsent = (
    key: "ad_storage" | "analytics_storage",
    allow: boolean
  ) => {
    if (scriptLoaded && typeof window !== "undefined") {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const consentOp: any = "consent";
      window.gtag(consentOp, "update", {
        [key]: allow ? "granted" : "denied",
      });
    }
  };

  //Load script only once
  useEffect(() => {
    //Script tags
    /**Async script (loads the GA tag) */
    const asyncScript = document.createElement("script");

    /**Sync script (runs after the GA tag load, emits a page view) */
    const syncScript = document.createElement("script");

    //Set properties for async script
    asyncScript.async = true; //Async is true
    asyncScript.src = `https://www.googletagmanager.com/gtag/js?id=${GA_IDS.TRACKING_GA4_ID}`; //GTAG url
    asyncScript.onload = () => {
      //On script loaded callback
      //Set loaded as true
      setScriptLoaded(true);

      //Update sync script content
      syncScript.innerHTML = `window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date()); gtag('config', '${
              GA_IDS.TRACKING_GA4_ID
            }', { 'send_page_view': false, 'debug_mode': ${
        process.env.NODE_ENV === "development" ? "true" : "false"
      } });`;

      //Execute sync script by appending it
      document.head.appendChild(syncScript);
    };

    //Execute async script
    document.head.appendChild(asyncScript);

    //On clean up, remove scripts
    return () => {
      document.head.removeChild(asyncScript);
      document.head.removeChild(syncScript);
    };
  }, []);

  return (
    <AnalyticsContext.Provider
      value={{
        emitCustomEvent,
        emitPageView,
        emitScreenView,
        updateConsent,
        emitEvent,
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
};

export default AnalyticsProvider;
