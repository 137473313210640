//React import
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
//Third parties import
import { FirebaseAppProvider } from "reactfire";
//Local imports
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { firebaseClientConfig } from "./config/firebase.config";
import * as ServiceWorker from "./serviceWorkerRegistration";
import AnalyticsProvider from "./context/analytics.context";

//Styles
import "./index.scss";
import Spinner from "./components/spinner/spinner.component";

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<Spinner />}>
      <AnalyticsProvider>
        <FirebaseAppProvider firebaseConfig={firebaseClientConfig}>
          <App />
        </FirebaseAppProvider>
      </AnalyticsProvider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

//Register service worker
// ServiceWorker.register();
ServiceWorker.register();
