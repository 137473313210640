import React from "react";
import { useContext, useEffect, useState } from "react";
import { PreloadContext } from "../../context/preload.context";
import { useNavigate } from "react-router-dom";
//interface
import { FooterDoc } from "../../interfaces/footer";
//styles
import "./footer.component.scss";

/** Footer component*/
const Footer = () => {
  const { getViewData, isReadyToLoad } = useContext(PreloadContext);

  let navigate = useNavigate();

  const [data, setData] = useState<FooterDoc | undefined>(getViewData("Footer") as FooterDoc | undefined);

  //On is ready to load
  useEffect(() => {
    if (isReadyToLoad) {
      setData(getViewData("Footer") as FooterDoc | undefined);
    }
    // eslint-disable-next-line
  }, [isReadyToLoad, getViewData("Footer")]);

  return (
    <footer className="footer_container" style={{"backgroundColor":data?.bgColor}}>
      <div className="footer">
        <div className="footer_info">
          <div className="footer_info_contact">
            <div
              className="footer_info_contact_title footer_info_center"
              dangerouslySetInnerHTML={{
                __html: data?.info.title ?? "",
              }}
            ></div>
            <div className="footer_info_center footer_info_icons ">
              {data?.info.mediaIcons.map(({ url, route }, i) => (
                <a
                  href={route}
                  target="_blank"
                  rel="noreferrer"
                  key={i}
                  className="footer_info_contact_anchor"
                >
                  <img key={url} className="footer_info_contact_icon " src={url} alt="" />
                </a>
              ))}
            </div>
            <div
              className="footer_info_contact_email footer_info_center"
              dangerouslySetInnerHTML={{
                __html: data?.info.email ?? "",
              }}
            ></div>
            <div
              className="footer_info_contact_subtitle footer_info_center"
              dangerouslySetInnerHTML={{
                __html: data?.info.locationTitle ?? "",
              }}
            ></div>
            <div
              className="footer_info_center"
              dangerouslySetInnerHTML={{
                __html: data?.info.location ?? "",
              }}
            ></div>
            <div
              className="footer_info_contact_subtitle footer_info_center"
              dangerouslySetInnerHTML={{
                __html: data?.info.contactTitle ?? "",
              }}
            ></div>
            <div
              className="footer_info_center"
              dangerouslySetInnerHTML={{
                __html: data?.info.contactText ?? "",
              }}
            ></div>
          </div>
          <div className="footer_info_links">
            <div className="footer_info_links_title">
              <h4>SPS Risk</h4>
            </div>
            {data?.links.map(({ name, route }, i) => (
              <span key={i} className="footer_info_link" onClick={() => navigate(route)}>
                {name}
              </span>
            ))}
          </div>
          <div className="footer_info_logo_container">
            <img className="footer_info_logo" src={data?.logoUrl} alt="logo" />
          </div>
        </div>
        <div
          className="footer_rights"
          dangerouslySetInnerHTML={{
            __html: data?.copyRightText ?? "",
          }}
        ></div>
      </div>
    </footer>
  );
};

export default Footer;
