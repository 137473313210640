//React imports
import { lazy } from "react";
//Third parties
import { Navigate, RouteProps } from "react-router-dom";
//Lazy loaded views
/**Home view */
const AetherView = lazy(() => import("../views/aether/aether.view"));

/**Contact us view */
const ContactView = lazy(() => import("../views/contact/contact.view"));

/**Team view */
const TeamView = lazy(() => import("../views/team/team.view"));

/**Services view */
const ServicesView = lazy(() => import("../views/services/services.view"));

/**About view */
const AboutView = lazy(() => import("../views/about/about.view"));

/**Aether view */
const HomeView = lazy(() => import("../views/home/home.view"));

export const routes: RouteProps[] = [
  {
    path: "/",
    element: <HomeView />,
  },
  {
    path: "/aether",
    element: <AetherView />,
  },
  {
    path: "/contact",
    element: <ContactView />,
  },
  {
    path: "/team",
    element: <TeamView />,
  },
  {
    path: "/services",
    element: <ServicesView />,
  },
  {
    path: "/about-us",
    element: <AboutView />,
  },
  {
    path: "*",
    element: <Navigate to="/" replace={true} />,
  },
];
